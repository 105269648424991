import React, { useCallback, useMemo } from "react"
import { useRouter, withRouter } from "next/router"
import { LayoutFooterV3, UtilCookies } from "@fxce/fe-core"
import { LocaleType } from "@fxce/fe-core/types/GlobalTypes"
import { isEmpty } from "lodash"
import { useSelector } from "react-redux"
import env from "@config/env"
// import { getTimezone } from "@helpers/utils"
import { putLang } from "@api/authAPI"

const Footer = () => {
  const router = useRouter()

  const lang = router.locale || "en"

  const user = useSelector((state: any) => state.rootUser?.user)
  const isAuthticated = useMemo(() => !isEmpty(user) && !!user.id, [user])

  const onChangeLanguage = useCallback(
    (value: string) => {
      UtilCookies.setCookie("LANGUAGE", value, {})
      router.locale = value
      router.push("#", "#", { locale: value })
      if (isAuthticated) {
        putLang(value)
      }
    },
    [isAuthticated, router]
  )

  // const onChangTimezone = useCallback(() => {}, [])

  return (
    <div className="dark-theme">
      <LayoutFooterV3
        env={env.APP_ENV}
        locale={lang as LocaleType}
        onChangeLang={onChangeLanguage}
        haveTimeZone={false}
      />
    </div>
  )
}

export default withRouter(Footer)
